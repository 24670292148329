import { ChangeEvent, FormEvent } from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
//
import { Tables } from "../../../types/database.types";
import routes from "../../../config/routes";
import { Link } from "react-router-dom";

export type RecordingFormData = Omit<
  Tables<"recordings">,
  | "slug"
  | "id"
  | "url"
  | "artwork_url"
  | "created_at"
  | "user_id"
  | "filename"
  | "updated_at"
> & {
  slug?: string;
  id?: string;
  url?: string;
  artwork_url?: string;
  created_at?: string;
  user_id?: number;
  filename?: string;
  updated_at?: string;
  collections: CollectionItem[];
};

export type CollectionItem = {
  collection: Tables<"collections">;
};

interface RecordingFormProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  formData: RecordingFormData;
  onInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    calculatedValue?: any
  ) => void;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
  artworkFile: File | null;
  loading: boolean;
  filename?: string;
  artworkUrl: string | null;
  clearArtworkUrl?: () => void;
  formButtonSubmitLabel?: string;
  availableCollections: Tables<"collections">[];
}

const RecordingForm: React.FC<RecordingFormProps> = ({
  onSubmit,
  formData,
  onInputChange,
  onFileChange,
  onImageChange,
  artworkFile,
  loading,
  filename,
  artworkUrl,
  clearArtworkUrl,
  formButtonSubmitLabel = "Upload",
  availableCollections
}) => {
  const truncateFilename = (name: string, limit: number = 30) => {
    if (name.length <= limit) return name;
    const extension = name.split(".").pop();
    const nameWithoutExt = name.slice(0, name.lastIndexOf("."));
    const truncated = nameWithoutExt.slice(0, limit - 3);
    return `${truncated}...${extension}`;
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack
        sx={{
          display: "flex",
          gap: 4,
          justifyContent: { sm: "space-between" },
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        {/* left */}
        <Box sx={{ flex: 1 }}>
          <div className="form-group mb-2">
            {filename ? (
              <>
                <label htmlFor="audio-file">audio file</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  readOnly
                  value={truncateFilename(filename)}
                />
              </>
            ) : (
              <>
                <label htmlFor="audio-file">audio file *</label>
                <input
                  id="audio-file"
                  className="form-control"
                  name="audio"
                  type="file"
                  accept={[
                    "audio/mpeg",
                    "audio/aac",
                    "audio/flac",
                    "audio/wav",
                    "audio/opus"
                  ].join(",")}
                  onChange={onFileChange}
                  alt="select an audio file"
                  required={!filename}
                />
              </>
            )}
          </div>

          <div className="form-group mb-2">
            <label htmlFor="title">title *</label>
            <input
              id="title"
              name="title"
              className="form-control"
              type="text"
              value={formData.title}
              required
              onChange={onInputChange}
            />
          </div>

          <div className="form-group mb-2">
            <label htmlFor="description">description</label>
            <textarea
              id="description"
              name="description"
              className="form-control"
              value={formData.description || ""}
              onChange={onInputChange}
            ></textarea>
          </div>

          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="is_public"
              name="is_public"
              checked={formData.is_public}
              onChange={onInputChange}
            />
            <label className="form-check-label" htmlFor="is_public">
              make public?
            </label>
          </div>
        </Box>

        {/* right */}
        <Box sx={{ flex: 1, mt: { xs: 2, sm: 0 } }}>
          {formData.url && (
            <div className="form-group mb-4">
              <label htmlFor="audio-playback">audio playback</label>
              <audio
                id="audio-playback"
                className="form-control"
                controls
                src={formData.url}
                aria-label="audio player for uploaded recording"
                style={{
                  border: "none",
                  padding: 0
                }}
              />
            </div>
          )}
          <div className="form-group mb-4">
            <label htmlFor="collections">collections</label>

            {availableCollections?.map((ac) => {
              return (
                <div key={ac.id} className="form-check">
                  <input
                    id={`collection-${ac.title}`}
                    name="collections"
                    className="form-check-input me-2"
                    type="checkbox"
                    checked={formData.collections.some(
                      (c) => c.collection.id === ac.id
                    )}
                    onChange={(e) => {
                      const indexFound = formData.collections.findIndex(
                        (c) => c.collection.id === ac.id
                      );

                      let newCollectionsValue: CollectionItem[];

                      if (indexFound !== -1) {
                        newCollectionsValue = formData.collections.filter(
                          (c) => c.collection.id !== ac.id
                        );
                      } else {
                        newCollectionsValue = [
                          ...formData.collections,
                          {
                            collection: ac
                          }
                        ];
                      }

                      onInputChange(e, newCollectionsValue);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`collection-${ac.title}`}
                  >
                    {ac.title}
                  </label>
                </div>
              );
            })}
          </div>

          {artworkFile ? (
            <div className="mb-4">
              <label>artwork image</label>
              <br />
              <img
                src={URL.createObjectURL(artworkFile)}
                style={{ height: 200, objectFit: "cover", marginTop: 8 }}
                alt={
                  formData.artwork_alt_text ||
                  `artwork image for recording: ${artworkFile.name}`
                }
              />

              <br />

              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() =>
                  onImageChange({ target: { files: null } } as any)
                }
              >
                remove artwork image
              </button>
            </div>
          ) : artworkUrl ? (
            <div className="mb-4">
              <label>artwork image</label>
              <br />
              <img
                src={artworkUrl}
                alt={
                  formData.artwork_alt_text ||
                  `artwork image for recording: ${artworkUrl}`
                }
                style={{ height: 200, objectFit: "cover", marginTop: 8 }}
              />
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => {
                  clearArtworkUrl!();
                  onImageChange({ target: { files: null } } as any);
                }}
              >
                remove artwork image
              </button>
            </div>
          ) : (
            <div className="form-group mb-2">
              <label htmlFor="artworkFile">artwork image</label>
              <input
                id="artworkFile"
                className="form-control"
                type="file"
                accept="image/*"
                onChange={onImageChange}
              />
            </div>
          )}

          <div className="form-group mb-2">
            <label htmlFor="artwork_alt_text">artwork alt text</label>
            <input
              id="artwork_alt_text"
              name="artwork_alt_text"
              className="form-control"
              type="text"
              value={formData.artwork_alt_text || ""}
              onChange={onInputChange}
            />
          </div>
        </Box>
      </Stack>

      <Box mt={2}>
        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "saving..." : formButtonSubmitLabel}
        </button>
      </Box>

      <div className="mt-4">
        <Link to={routes.dashboard}>back</Link>
      </div>
    </form>
  );
};

export default RecordingForm;
