import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
//
import { localStorageKeys } from "../../services/local-storage";

// Create a serializable version of MediaDeviceInfo
interface SerializableMediaDevice {
  deviceId: string;
  groupId: string;
  kind: MediaDeviceKind;
  label: string;
}

interface SettingsState {
  loading: boolean;
  error?: string;
  audioInputMediaDevices: SerializableMediaDevice[];
  selectedAudioInputId: string | null;
}

const buildInitialState = (): SettingsState => {
  let selectedAudioInputId = "default";

  const selectedAudioInputIdFromCache = localStorage.getItem(
    localStorageKeys.SELECTED_AUDIO_INPUT_ID
  );
  if (selectedAudioInputIdFromCache) {
    selectedAudioInputId = selectedAudioInputIdFromCache;
  }

  return {
    loading: false,
    audioInputMediaDevices: [],
    selectedAudioInputId
  };
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: buildInitialState(),
  reducers: {
    setSelectedAudioInput: (state, action: PayloadAction<string>) => {
      state.selectedAudioInputId = action.payload;
      localStorage.setItem(
        localStorageKeys.SELECTED_AUDIO_INPUT_ID,
        action.payload
      );
    },
    resetSelectedAudioInput: (state) => {
      state.selectedAudioInputId = null;
      localStorage.removeItem(localStorageKeys.SELECTED_AUDIO_INPUT_ID);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaDevices.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchMediaDevices.fulfilled, (state, action) => {
        // Transform MediaDeviceInfo objects into serializable format
        state.audioInputMediaDevices = action.payload.map((device) => ({
          deviceId: device.deviceId,
          groupId: device.groupId,
          kind: device.kind,
          label: device.label
        }));
        state.loading = false;

        // Validate that selected audio input still exists,
        // if not, clear the cache and set to default input device
        const audioInputIds = new Set(action.payload.map((d) => d.deviceId));
        if (
          state.selectedAudioInputId &&
          !audioInputIds.has(state.selectedAudioInputId)
        ) {
          state.selectedAudioInputId = "default";
          localStorage.removeItem(localStorageKeys.SELECTED_AUDIO_INPUT_ID);
        }
      })
      .addCase(fetchMediaDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchMediaDevices = createAsyncThunk(
  "settings/fetchMediaDevices",
  async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      // Transform the devices into serializable objects before returning
      return devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          deviceId: device.deviceId,
          groupId: device.groupId,
          kind: device.kind,
          label: device.label
        }));
    } catch {
      throw new Error("failed to enumerate media devices");
    }
  }
);

export const { setSelectedAudioInput, resetSelectedAudioInput } =
  settingsSlice.actions;

export default settingsSlice.reducer;
