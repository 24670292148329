import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
//
import env from "../../config/env";
import { isUrlSafe } from "../../utils/formatters";
import { formatElapsedTime } from "../../utils/formatters/time-elapsed";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchMediaDevices,
  setSelectedAudioInput
} from "../../store/settings/slice";
import routes from "../../config/routes";
import { getErrorMessage } from "../../utils/error-handlers";
import apiClient from "../../services/api";
import { StreamS3Meta } from "../../types";

const AudioStreamer: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    loading: audioInputMediaDevicesLoading,
    error: audioInputMediaDevicesError,
    selectedAudioInputId,
    audioInputMediaDevices
  } = useAppSelector((state) => state.settings);

  const streamUrlRef = useRef<HTMLInputElement>(null);
  const streamPlaylistUrlRef = useRef<HTMLInputElement>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState("");
  const [loading, setLoading] = useState(false);

  const [streamTitle, setStreamTitle] = useState<string>(""); // user provided name
  const [vanityUrl, setVanityUrl] = useState<string>(""); // user provided path (optional)
  const [isPublic, setIsPublic] = useState(true);
  const [serverStreamObject, setServerStreamObject] =
    useState<StreamS3Meta | null>(null);

  // necessary to fix the stale closure issue when sending updates
  const serverStreamRef = useRef(serverStreamObject);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const handleDeviceChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedAudioInput(event.target.value));
  };

  const handleRefreshMediaDevices = () => {
    dispatch(fetchMediaDevices());
  };

  // Update the ref whenever serverStreamObject changes
  useEffect(() => {
    serverStreamRef.current = serverStreamObject;
  }, [serverStreamObject]);

  // show timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (startTime) {
        setElapsedTime(formatElapsedTime(startTime));
      } else {
        setElapsedTime("");
      }
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [startTime]);

  useEffect(() => {
    dispatch(fetchMediaDevices());
  }, [dispatch]);

  const startStreaming = async () => {
    if (selectedAudioInputId === null) return alert("device not selected");
    if (!streamTitle) return alert("stream name is required");

    // trim & validate vanity url
    let trimmedVanityUrl = vanityUrl.trim();
    if (!isUrlSafe(trimmedVanityUrl))
      return alert(
        "url path must only contain letters, numbers, dashes, and underscores"
      );
    setVanityUrl(trimmedVanityUrl);

    setLoading(true);

    try {
      let audio: MediaTrackConstraints | boolean;
      if (selectedAudioInputId === "default") {
        audio = true;
      } else {
        audio = { deviceId: { exact: selectedAudioInputId } };
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio });

      const recorder = new MediaRecorder(stream);

      recorder.start(2000); // send chunks every 2 seconds

      // On data available, send chunk to /live/:streamId
      recorder.ondataavailable = async (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          const formData = new FormData();
          formData.append("audio", event.data, "chunk.webm");

          if (!serverStreamRef.current) return alert("no stream ref found");

          await apiClient.post(
            `${env.apiHost}/streams/live/${serverStreamRef.current.id}`,
            formData
          );
        }
      };

      recorder.onerror = async (error) => {
        console.log("recorder.onerror", error);
        throw error;
      };

      mediaRecorderRef.current = recorder;

      // Start the stream on the server
      const startPayload = {
        title: streamTitle,
        vanityUrl: trimmedVanityUrl,
        isPublic
      };

      const { data } = await apiClient.post<StreamS3Meta>(
        "/streams/start",
        startPayload
      );

      setServerStreamObject(data);
      setStartTime(new Date());
      alert("stream successfully started!");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      alert(getErrorMessage(e));
      stopStreaming();
    } finally {
      setLoading(false);
    }
  };

  const stopStreaming = async () => {
    try {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }

      if (!serverStreamRef.current) throw Error("no stream found");

      await apiClient.post(
        `${env.apiHost}/streams/stop/${serverStreamRef.current.id}`
      );

      setStartTime(null);
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setServerStreamObject(null);
    }
  };

  return (
    <div>
      <h1 hidden>manage stream</h1>

      <div className="row">
        <div className="col-12 col-md-6">
          <h2>configuration</h2>

          <div className="form-group mb-3">
            <label htmlFor="stream-name">stream name *</label>
            <input
              id="stream-name"
              className="form-control"
              required
              type="text"
              placeholder="e.g., john's relaxing piano stream"
              value={serverStreamObject?.title}
              onChange={(e) => setStreamTitle(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="url-path">vanity url path (optional)</label>
            <input
              id="url-path"
              className="form-control"
              type="text"
              placeholder="e.g., johns-relaxing-piano-stream"
              value={vanityUrl}
              onChange={(e) => setVanityUrl(e.target.value)}
            />
          </div>

          <div className="form-check mb-3">
            <input
              id="is-public"
              type="checkbox"
              className="form-check-input"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="is-public">
              make public?
            </label>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="audio-input">audio input source</label>
            <select
              id="audio-input"
              className="form-control"
              value={selectedAudioInputId || ""}
              onChange={handleDeviceChange}
            >
              {audioInputMediaDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `microphone ${device.deviceId}`}
                </option>
              ))}
            </select>
            <button
              className="btn btn-outline-primary mt-3"
              onClick={handleRefreshMediaDevices}
              disabled={audioInputMediaDevicesLoading}
            >
              {audioInputMediaDevicesLoading
                ? "loading.."
                : "refresh audio input sources"}
            </button>
            <div aria-label="audio input source error">
              {audioInputMediaDevicesError}
            </div>
          </div>

          <div className="mb-4">
            {serverStreamObject ? (
              <button
                className="btn btn-danger"
                onClick={stopStreaming}
                disabled={loading}
              >
                stop streaming
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={startStreaming}
                disabled={loading}
              >
                go live now
              </button>
            )}
          </div>
          <p className="text-secondary">
            keep this webpage open to keep your stream alive. closing the tab
            will result in the stream ending.
          </p>
        </div>

        {serverStreamObject && (
          <div className="col-12 col-md-6" aria-live="polite">
            <h2>details</h2>

            <div className="form-group mb-3">
              <label htmlFor="stream-url">stream url</label>
              <div className="input-group mb-3">
                <input
                  id="stream-url"
                  className="form-control text-primary"
                  ref={streamUrlRef}
                  onClick={() => {
                    window.open(
                      `${env.clientHost}/streams/${serverStreamObject.id}?external=false`,
                      "_blank"
                    );
                  }}
                  type="text"
                  disabled={!serverStreamObject.url}
                  value={`${env.clientHost}/streams/${serverStreamObject.id}?external=false`}
                  readOnly
                  aria-label="live stream url"
                  aria-description="this is the public url which you can share with others. click this to open the url in a new tab."
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      if (!streamUrlRef.current)
                        return alert("unable to find stream url");

                      const url = streamUrlRef.current.value;
                      navigator.clipboard.writeText(url);
                      alert("url copied to clipboard");
                    }}
                  >
                    copy url
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="stream-playlist-url">
                stream playlist url (typically used for app integrations)
              </label>
              <div className="input-group mb-3">
                <input
                  id="stream-playlist-url"
                  className="form-control text-primary"
                  ref={streamPlaylistUrlRef}
                  onClick={() => {
                    window.open(serverStreamObject.url, "_blank");
                  }}
                  type="text"
                  disabled={!serverStreamObject.url}
                  value={serverStreamObject.url}
                  readOnly
                  aria-label="live stream url"
                  aria-description="this is the stream playlist url which you can share with developers looking to integrate this stream into things like websites and mobile apps. click this to open the url in a new tab."
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      if (!streamPlaylistUrlRef.current)
                        return alert("unable to find stream playlist url");

                      const url = streamPlaylistUrlRef.current.value;
                      navigator.clipboard.writeText(url);
                      alert("playlist url copied to clipboard");
                    }}
                  >
                    copy url
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="form-group mb-3">
              <label htmlFor="current-listeners">current listeners</label>
              <input
                id="current-listeners"
                className="form-control pe-none"
                type="text"
                value={currentListeners?.toFixed()}
                aria-live="polite"
                readOnly
              />
            </div> */}

            <div className="form-group mb-3">
              <label htmlFor="time-elapsed">time elapsed</label>
              <input
                id="time-elapsed"
                className="form-control pe-none"
                type="text"
                value={elapsedTime}
                aria-live="polite"
                readOnly
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <Link to={routes.dashboard}>back</Link>
      </div>
    </div>
  );
};

export default AudioStreamer;
