import { memo, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { Box } from "@mui/material";
//
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import routes from "../../../config/routes";
import fallbackImage from "../../../assets/images/generic-artwork-image.jpg";
import LiveBadge from "../../../components/live-badge";
import MediaPlayer from "../../../components/media-player";
import { formatDistance } from "date-fns";

const MemoizedMediaPlayer = memo(({ url }: { url: string }) => (
  <MediaPlayer
    url={url}
    onError={(e) => {
      console.error(e);
      Sentry.captureException(e);
    }}
  />
));

const StreamsListenPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [elapsedTime, setElapsedTime] = useState("");
  const [stream, setStream] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // reminder: this returns a STRING
  const isExternal: string | null = searchParams.get("external");

  // redirect if no stream is found
  useEffect(() => {
    if (!id) {
      navigate(routes.dashboard);
    }
  }, [id, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (isExternal === null) {
        alert("invalid url, please try another one");
        navigate(routes.discover);
      }

      try {
        setLoading(true);

        if (isExternal === "true") {
          console.log("trying1");
          const { data } = await apiClient.get(`/external-streams/${id}`);
          setStream(data);
        } else {
          const { data } = await apiClient.get(`/streams/${id}`);
          setStream(data);
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        setError(errorMessage);
        alert(errorMessage);
      } finally {
        console.log("loading done");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // show timer
  useEffect(() => {
    console.log("Stream", stream);
    if (!stream) return;
    const intervalId = setInterval(() => {
      console.log("looping", stream);
      if (stream.startedAt) {
        setElapsedTime(
          `stream started ${formatDistance(
            new Date(stream.startedAt),
            new Date(),
            {
              addSuffix: true
            }
          )}`
        );
      } else {
        setElapsedTime("");
      }
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [stream]);

  if (loading) return <div>loading...</div>;
  if (!stream || !stream.url) return <Navigate to={routes.discover} />;

  return (
    <main>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 hidden>stream</h1>
      </div>

      <Box
        component="section"
        id="playback"
        sx={{
          backgroundColor: "#f7f7f7",
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: {
            xs: 4
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          paddingBottom: {
            xs: 2
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          textAlign: "center",
          borderRadius: 4
        }}
      >
        <Box>
          <LiveBadge />
        </Box>
        <Box
          component="img"
          src={stream.artwork_url || fallbackImage}
          alt={`image for stream ${stream.title}`}
          sx={{
            height: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            width: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            objectFit: "cover", // prevent image from getting distorted/losing aspect ratio
            borderRadius: 2,
            marginTop: 2,
            marginBottom: 2,
            border: "1px solid grey"
          }}
        />

        <h1>{stream.title}</h1>
        <i>{elapsedTime || "no elapsed time found"}</i>

        <div className="py-4">
          <MemoizedMediaPlayer url={stream.url} />
        </div>

        <p>{stream.description}</p>
      </Box>

      <div aria-label="error">{error}</div>
      <div aria-label="is loading">{loading}</div>

      <div className="mt-4">
        <Link to={routes.discover}>back</Link>
      </div>
    </main>
  );
};

export default StreamsListenPage;
