import { Link, useNavigate } from "react-router-dom";
//
import { useAuth } from "../../components/auth-provider";
import routes from "../../config/routes";
import { ChangeEvent, useEffect, useState } from "react";
import { Tables } from "../../types/database.types";
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchMediaDevices,
  setSelectedAudioInput
} from "../../store/settings/slice";
import MicrophoneTester from "../../components/microphone-tester";

const SettingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    loading: audioInputMediaDevicesLoading,
    error: audioInputMediaDevicesError,
    selectedAudioInputId,
    audioInputMediaDevices
  } = useAppSelector((state) => state.settings);
  const { session, handleSignOut } = useAuth();

  // new password
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newPasswordLoading, setNewPasswordLoading] = useState(false);

  // user_api_keys
  const [userAPIKeys, setUserAPIKeys] = useState<Tables<"user_api_keys">[]>([]);
  const [userAPIKeysError, setUserAPIKeysError] = useState("");
  const [userAPIKeysLoading, setUserAPIKeysLoading] = useState(false);

  const handleDeviceChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedAudioInput(event.target.value));
  };

  const requestLogout = async () => {
    handleSignOut();
    navigate(routes.home);
  };

  const handleGenerateNewUserAPIKey = async () => {
    const name = prompt("what would you like to name the api key?");

    try {
      setUserAPIKeysLoading(true);
      setUserAPIKeysError("");

      await apiClient.post("/user-api-keys/", {
        friendly_name: name
      });

      // fetching new data to get an ID
      fetchUserAPIKeys();
    } catch (e) {
      setUserAPIKeysError(getErrorMessage(e));
    } finally {
      setUserAPIKeysLoading(false);
    }
  };

  const handleDeleteUserAPIKey = async (k: Tables<"user_api_keys">) => {
    const shouldContinue = confirm(
      `are you sure you want to delete the following api key? ${k.friendly_name}`
    );

    if (!shouldContinue) {
      return;
    }

    try {
      setUserAPIKeysLoading(true);
      setUserAPIKeysError("");

      await apiClient.delete(`/user-api-keys/${k.id}`);

      // remove from array
      const newData = userAPIKeys.filter((apiKey) => apiKey.id !== k.id);
      setUserAPIKeys(newData);
    } catch (e) {
      setUserAPIKeysError(getErrorMessage(e));
    } finally {
      setUserAPIKeysLoading(false);
    }
  };

  const fetchUserAPIKeys = async () => {
    try {
      setUserAPIKeysLoading(true);
      setUserAPIKeysError("");
      const { data } = await apiClient.get("/user-api-keys/mine");
      setUserAPIKeys(data);
    } catch (e) {
      console.error(e);
      setUserAPIKeysError("there was an error fetching the streams");
    } finally {
      setUserAPIKeysLoading(false);
    }
  };

  const handleRefreshMediaDevices = () => {
    dispatch(fetchMediaDevices());
  };

  const updatePassword = async () => {
    try {
      if (!newPassword) throw Error("missing password");
      if (newPassword !== newPasswordConfirmation)
        throw Error("passwords don't match");
      setNewPasswordLoading(true);
      await apiClient.post("/user/change-password", { newPassword });
      setNewPassword("");
      setNewPasswordConfirmation("");
      alert("successfully changed password!");
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setNewPasswordLoading(false);
    }
  };

  useEffect(() => {
    fetchUserAPIKeys();
    dispatch(fetchMediaDevices());
  }, [dispatch]);

  return (
    <main>
      <h1 hidden>settings</h1>

      <section id="general" className="mb-4">
        <h2>general</h2>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group mb-3">
              <label htmlFor="email">email</label>
              <input
                id="email"
                className="form-control"
                type="email"
                readOnly
                disabled
                value={session?.user.email}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="new-password">new password</label>
              <input
                id="new-password"
                className="form-control"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="confirm-new-password">confirm new password</label>
              <input
                id="confirm-new-password"
                className="form-control"
                type="password"
                value={newPasswordConfirmation}
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                autoComplete="new-password"
              />
            </div>

            <button
              className="btn btn-outline-primary mt-3"
              onClick={updatePassword}
              disabled={newPasswordLoading}
            >
              save
            </button>
          </div>
        </div>
      </section>

      <section id="audio" className="mb-4">
        <h2>audio</h2>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group mb-3">
              <label htmlFor="input-source">input source</label>

              <select
                id="input-source"
                className="form-control"
                value={selectedAudioInputId || ""}
                onChange={handleDeviceChange}
              >
                {audioInputMediaDevicesLoading ? (
                  <option>loading...</option>
                ) : audioInputMediaDevicesError ? (
                  <option>error fetching audio devices</option>
                ) : (
                  audioInputMediaDevices.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label || `microphone ${device.deviceId}`}
                    </option>
                  ))
                )}
              </select>

              <button
                className="btn btn-outline-primary mt-3"
                onClick={handleRefreshMediaDevices}
              >
                refresh audio input sources
              </button>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="stream-quality">stream quality</label>
              <select
                id="stream-quality"
                className="form-control"
                value={0}
                onChange={handleDeviceChange}
              >
                <option value={0}>.mp3 (128kbps - stereo)</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="test-audio">test microphone</label>
              <MicrophoneTester />
            </div>
          </div>
        </div>
      </section>

      <section id="developer" className="mb-4">
        <h2>developer</h2>
        <p>
          manage external access to your live streams and recordings using our
          apis. want to read through our developer documentation on how to
          interface with our system?{" "}
          <Link to={routes.documentation}>check it out here.</Link>
        </p>

        <div role="group" className="my-3">
          <h4> api keys</h4>

          <div role="list" className="list-group" aria-live="polite">
            {userAPIKeysLoading ? (
              <div>loading...</div>
            ) : userAPIKeys.length ? (
              userAPIKeys.map((k) => {
                return (
                  <div
                    key={k.id}
                    role="listitem"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  >
                    <div
                      style={{
                        maxWidth: "calc(100% - 80px)",
                        overflow: "scroll"
                      }}
                    >
                      <div
                        style={{ fontWeight: 600 }}
                        aria-label="friendly name for api key"
                      >
                        {k.friendly_name || "unnamed api key"}
                      </div>
                      <div aria-label="api key value">{k.api_key}</div>
                    </div>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteUserAPIKey(k)}
                    >
                      delete
                    </button>
                  </div>
                );
              })
            ) : (
              <div>no api keys found</div>
            )}

            {userAPIKeysError && <div>{userAPIKeysError}</div>}
          </div>

          <button
            className="btn btn-outline-primary mt-3"
            onClick={handleGenerateNewUserAPIKey}
          >
            generate api key
          </button>
        </div>
      </section>

      <section id="logout" className="mb-4">
        <h2>log out</h2>
        <p>want to end your session? click the button below to log out.</p>
        <button className="btn btn-outline-danger mt-3" onClick={requestLogout}>
          log out
        </button>
      </section>

      <section id="danger-area" className="mb-4">
        <h2>danger area</h2>
        <div>want to close your account?</div>
        <button
          className="btn btn-danger mt-3"
          onClick={() =>
            alert("please email us at streamfortheblind@gmail.com")
          }
        >
          request deletion
        </button>
      </section>
    </main>
  );
};

export default SettingsPage;
