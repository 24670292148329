import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import { getErrorMessage } from "../../utils/error-handlers";

interface MediaPlayerProps {
  url: string;
  onError?: (error: Error) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  className?: string;
  autoPlay?: boolean;
}

type MediaElementType = HTMLVideoElement | HTMLAudioElement;

const MediaPlayer: React.FC<MediaPlayerProps> = ({
  url,
  onError,
  onPlay,
  onPause,
  onEnded,
  className = "",
  autoPlay = true
}) => {
  const mediaRef = useRef<MediaElementType>(null);
  const hlsRef = useRef<Hls | null>(null);

  useEffect(() => {
    if (!url || !mediaRef.current) return;

    const setupPlayer = () => {
      const isHLS = url.endsWith(".m3u8");

      if (!mediaRef || !mediaRef.current) return alert("no media player found");

      if (isHLS) {
        if (Hls.isSupported()) {
          // Cleanup existing HLS instance
          if (hlsRef.current) {
            hlsRef.current.destroy();
          }

          // Create new HLS instance
          const hls = new Hls({
            enableWorker: true,
            lowLatencyMode: true,
            backBufferLength: 30,
            maxBufferSize: 0,
            maxBufferLength: 4
          });

          hlsRef.current = hls;

          hls.loadSource(url);
          hls.attachMedia(mediaRef.current);

          hls.on(Hls.Events.ERROR, (_: any, data: any) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.log("Network error, attempting recovery...");
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("Media error, attempting recovery...");
                  hls.recoverMediaError();
                  break;
                default:
                  console.error("Fatal error:", data);
                  hls.destroy();
                  onError?.(new Error(`HLS fatal error: ${data.type}`));
                  break;
              }
            }
          });
        } else if (
          mediaRef.current.canPlayType("application/vnd.apple.mpegurl")
        ) {
          // Native HLS support (Safari)
          mediaRef.current.src = url;
        } else {
          onError?.(new Error("HLS is not supported in this browser"));
        }
      } else {
        // Regular media URL
        mediaRef.current.src = url;
      }
    };

    setupPlayer();

    // Cleanup function
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
      if (mediaRef.current) {
        mediaRef.current.removeAttribute("src");
        mediaRef.current.load();
      }
    };
  }, [url, onError]);

  // Event handlers
  useEffect(() => {
    const mediaElement = mediaRef.current;
    if (!mediaElement) return;

    const handleError = (e: Event) => {
      onError?.(new Error(`media playback error: ${getErrorMessage(e)}`));
    };

    const handlePlay = () => onPlay?.();
    const handlePause = () => onPause?.();
    const handleEnded = () => onEnded?.();

    mediaElement.addEventListener("error", handleError);
    mediaElement.addEventListener("play", handlePlay);
    mediaElement.addEventListener("pause", handlePause);
    mediaElement.addEventListener("ended", handleEnded);

    return () => {
      mediaElement.removeEventListener("error", handleError);
      mediaElement.removeEventListener("play", handlePlay);
      mediaElement.removeEventListener("pause", handlePause);
      mediaElement.removeEventListener("ended", handleEnded);
    };
  }, [onError, onPlay, onPause, onEnded]);

  const isAudioOnly = url?.toLowerCase().endsWith(".mp3");
  const MediaElement = isAudioOnly ? "audio" : "video";

  console.log("rendering media player o gad");

  return (
    <div className="w-100" style={{ height: 50 }}>
      <MediaElement
        ref={mediaRef as any}
        className={className}
        controls
        playsInline
        autoPlay={autoPlay}
        onError={(e) =>
          onError?.(new Error(`media playback error: ${getErrorMessage(e)}`))
        }
        style={{ width: "100%", height: "50px" }}
      />
    </div>
  );
};

export default MediaPlayer;
