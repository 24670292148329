import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "@mui/material";
//
import routes from "../../config/routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import LiveBadge from "../../components/live-badge";
import { fetchMyCollections } from "../../store/my-collections/slice";
import { fetchMyRecordings } from "../../store/my-recordings/slice";
import { fetchMyStreams } from "../../store/my-streams/slice";

function DashboardPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    loading: myStreamsLoading,
    error: myStreamsError,
    data: myStreams
  } = useAppSelector((state) => state.myStreams);

  const {
    loading: myRecordingsLoading,
    error: myRecordingsError,
    data: myRecordings
  } = useAppSelector((state) => state.myRecordings);
  const {
    loading: myCollectionsLoading,
    error: myCollectionsError,
    data: myCollections
  } = useAppSelector((state) => state.myCollections);

  const loadStreamPage = () => {
    navigate(routes.stream);
  };

  const loadNewRecordingPage = () => {
    navigate(routes.recordingsNew);
  };

  const loadRecordingDetail = (id: number) => {
    navigate(`/recordings/${id}/detail`);
  };

  const loadCollectionDetail = (id: number) => {
    navigate(`/collections/${id}/detail`);
  };

  // fetch data once authenticated, but cache it and prevent redundant calls after the fact
  useEffect(() => {
    if (myStreams.length || myRecordings.length || myCollections.length) return;
    dispatch(fetchMyCollections());
    dispatch(fetchMyRecordings());
    dispatch(fetchMyStreams());
  }, []);

  return (
    <main>
      <h1 hidden>dashboard</h1>

      <section id="streams" className="mb-4">
        <div
          className="mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my live streams</h2>
          <button className="btn btn-primary" onClick={loadStreamPage}>
            go live now
          </button>
        </div>
        {myStreamsLoading ? (
          <div>loading...</div>
        ) : myStreamsError ? (
          <div>{myStreamsError}</div>
        ) : myStreams.length ? (
          <div className="list-group" role="list">
            {myStreams.map((myStream) => (
              <Link
                className="list-group-item list-group-item-action"
                role="listitem"
                key={myStream.title}
                to={`/streams/${myStream.id}?external=${myStream.is_external_stream}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md: "flex-start" }
                  }}
                >
                  <Box className="pe-2 d-flex align-items-center">
                    <div>
                      <i
                        className="bi bi-play-circle me-3 text-success"
                        aria-label="play button icon"
                      />
                    </div>
                    <div>
                      <span className="me-2">{myStream.title}</span>
                    </div>
                  </Box>
                  <div className="ms-3 d-inline-block">
                    <LiveBadge />
                  </div>
                </Box>
              </Link>
            ))}
          </div>
        ) : (
          <i>no live streams found</i>
        )}
      </section>

      <section id="recordings" className="mb-4">
        <div
          className="mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my recordings</h2>
          <button
            className="btn btn-outline-primary"
            onClick={loadNewRecordingPage}
          >
            upload recording
          </button>
        </div>
        {myRecordingsLoading ? (
          <div>loading...</div>
        ) : myRecordingsError ? (
          <div>{myRecordingsError}</div>
        ) : myRecordings.length ? (
          <div className="list-group" role="list">
            {myRecordings.map((r) => (
              <button
                onClick={() => loadRecordingDetail(r.id)}
                key={r.id}
                className="list-group-item list-group-item-action"
                role="listitem"
              >
                <div className="d-flex align-items-center">
                  <i
                    className="bi bi-pencil-square me-3 text-primary"
                    aria-label="edit button icon"
                  />
                  <span>{r.title}</span>
                </div>
              </button>
            ))}
          </div>
        ) : (
          <i>no streams found</i>
        )}
      </section>

      {/* my collections */}
      <section id="collections" className="mb-4">
        <div
          className="mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my collections</h2>
          <button
            className="btn btn-outline-primary"
            onClick={() => navigate(routes.collectionsNew)}
          >
            new collection
          </button>
        </div>

        {myCollectionsLoading ? (
          <div>loading...</div>
        ) : myCollectionsError ? (
          <div>{myCollectionsError}</div>
        ) : myCollections.length ? (
          <div className="list-group" aria-label="list of collections">
            {myCollections.map((c) => (
              <button
                key={c.id}
                aria-label="collection item"
                className="list-group-item list-group-item-action"
                onClick={() => loadCollectionDetail(c.id)}
              >
                <i
                  className="bi bi-pencil-square me-3 text-primary"
                  aria-label="edit button icon"
                />
                {c.title}
              </button>
            ))}
          </div>
        ) : (
          <i>no collections found</i>
        )}
      </section>
    </main>
  );
}

export default DashboardPage;
