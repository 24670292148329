import styled from "styled-components";

const PlansPageStyled = styled.div`
  .pricing-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1200px;
    margin: auto;
  }

  .pricing-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    padding: 1.5rem;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pricing-card h3 {
    font-size: 1.5em;
    color: #333;
    margin-top: 0;
    opacity: 1;
  }

  .pricing-card p {
    /* color: #555; */
    margin: 0.5rem 0;
  }

  .features {
    margin: 1rem 0;
    font-weight: bold;
  }

  .price {
    font-size: 1.8em;
    /* color: #333; */
    margin: 1rem 0;
  }

  .price span {
    font-size: 0.8em;
    color: #777;
  }

  .cta-button {
    background-color: #333;
    color: #fff;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 1rem;
  }

  .cta-button.green {
    background-color: #007207;
  }

  .cta-button.blue {
    background-color: #007acc;
  }

  .benefits {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-top: 1rem;
  }

  .benefits li {
    margin: 0.5rem 0;
    color: #333;
    display: flex;
    align-items: center;
  }

  .popular-badge {
    background-color: #0d6efd;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    font-size: 0.8em;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export default PlansPageStyled;
