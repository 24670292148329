import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* layout */

  .container {
    max-width: 1000px;
    /* margin-left: auto;
    margin-right: auto; */
  }

  a {
    text-decoration: none;
  }

  a.active {
    // color: red;
    font-weight: 600;
    text-decoration: underline;
  }

  /* form helpers */

  .form-container {
    max-width: 400px;
  }

  /* colors */

  .text-red {
    color: red;
  }

  /* lists */

  ul.ul-1 {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 0;

    li {
      list-style: none;

      input {
        margin-right: 8px;
      }
    }
  }

  /* typography */

  h2 {
    opacity: 0.8;
  }
  h3 {
    opacity: 0.6;
  }
  h4 {
    opacity: 0.55;
  }
`;

export default GlobalStyles;
