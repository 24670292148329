import {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode
} from "react";
import { Session } from "@supabase/supabase-js";
//
import supabase from "../../services/supabase";
import { getErrorMessage } from "../../utils/error-handlers";

// Define types for AuthContext
interface AuthContextType {
  session: Session | null;
  handleSignOut: () => Promise<void>;
}

// Create AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    // Check for an active session
    const getSession = async () => {
      const {
        data: { session: currentSession }
      } = await supabase.auth.getSession();
      setSession(currentSession);
    };

    getSession();

    // Listen for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_, session) => {
        setSession(session);
      }
    );

    // Cleanup on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) console.error("Sign-out error:", error.message);
    } catch (e) {
      console.error("error signing out: ", getErrorMessage(e));

      // empty everything and force refresh - we don't want the user getting stuck in a loop or with problems here
      localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <AuthContext.Provider value={{ session, handleSignOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
