import { Link } from "react-router-dom";
//
import { HomePageStyled } from "../home/styles";
import { useAppSelector } from "../../store/hooks";
import LiveBadge from "../../components/live-badge";
import { Box } from "@mui/material";

function HomePage() {
  const {
    loading: allStreamsLoading,
    error: allStreamsError,
    data: allStreams
  } = useAppSelector((state) => state.allStreams);

  const {
    loading: externalStreamsLoading,
    error: externalStreamsError,
    data: externalStreams
  } = useAppSelector((state) => state.externalStreams);
  const {
    loading: allRecordingsLoading,
    error: allRecordingsError,
    data: allRecordings
  } = useAppSelector((state) => state.allRecordings);

  return (
    <HomePageStyled>
      <h1 hidden>discover</h1>

      <section id="discover-live-streams" className="mb-4">
        <div className="mb-2">
          <h2>streaming now</h2>
        </div>

        {allStreamsLoading || externalStreamsLoading ? (
          <div>loading...</div>
        ) : allStreamsError || externalStreamsError ? (
          <div>{allStreamsError || externalStreamsError}</div>
        ) : allStreams.length || externalStreams.length ? (
          <div className="list-group" role="list">
            {[...allStreams, ...externalStreams].map((as) => (
              <Link
                className="list-group-item list-group-item-action"
                role="listitem"
                key={as.title}
                to={`/streams/${as.id}?external=${
                  as.is_external_stream ? "true" : "false"
                }`}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md: "flex-start" }
                  }}
                >
                  <Box className="pe-2 d-flex align-items-center">
                    <div>
                      <i
                        className="bi bi-play-circle me-3 text-success"
                        aria-label="play button icon"
                      />
                    </div>
                    <div>
                      <span className="me-2">{as.title}</span>
                    </div>
                  </Box>
                  <div className="ms-3 d-inline-block">
                    <LiveBadge />
                  </div>
                </Box>
              </Link>
            ))}
          </div>
        ) : (
          <i>no live streams found</i>
        )}
      </section>

      <section id="discover-recordings" className="mb-4">
        <div className="mb-2">
          <h2>recordings</h2>
        </div>

        {allRecordingsLoading ? (
          <div>loading...</div>
        ) : allRecordingsError ? (
          <div>{allRecordingsError}</div>
        ) : allRecordings.length ? (
          <div className="list-group" role="list">
            {allRecordings.map((r) => (
              <Link
                key={r.id}
                to={`/recordings/${r.id}`}
                className="list-group-item list-group-item-action"
                role="listitem"
              >
                <i
                  className="bi bi-play-circle me-3 text-success"
                  aria-label="play button icon"
                />

                {r.title}
              </Link>
            ))}
          </div>
        ) : (
          <i>no recordings found</i>
        )}
      </section>
    </HomePageStyled>
  );
}

export default HomePage;
