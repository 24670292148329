import { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//
import routes from "../../../config/routes";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import { useAppDispatch } from "../../../store/hooks";
import { fetchMyCollections } from "../../../store/my-collections/slice";

const CollectionsNewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [artworkFile, setArtworkFile] = useState<File | null>(null);
  const [artworkAltText, setArtworkAltText] = useState<string>("");
  const [makePublic, setMakePublic] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Create FormData object to send files and form data
      const formData = new FormData();

      // Append form fields
      formData.append("title", title);
      if (description) {
        formData.append("description", description);
      }
      formData.append("is_public", JSON.stringify(makePublic));
      formData.append("artwork_alt_text", artworkAltText);

      if (artworkFile) {
        formData.append("artwork", artworkFile);
      }

      // Send the request
      await apiClient.post("/collections", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      dispatch(fetchMyCollections());

      alert("collection created successfully!");

      navigate(routes.dashboard);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      alert(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Safely access the first file
    if (file) {
      setArtworkFile(file);
    } else {
      setArtworkFile(null);
    }
  };

  return (
    <main>
      <h1>create collection</h1>

      <form onSubmit={handleFormSubmit} className="form-container">
        <div className="form-group mb-3">
          <label htmlFor="title">title</label>
          <input
            id="title"
            className="form-control"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">description</label>
          <textarea
            id="description"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="makePublic"
            checked={makePublic}
            onChange={(e) => setMakePublic(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="makePublic">
            make public?
          </label>
        </div>

        {artworkFile ? (
          <div className="mb-4">
            <label>artwork image</label>
            <br />
            <img
              src={URL.createObjectURL(artworkFile)}
              style={{ height: 200, objectFit: "cover", marginTop: 8 }}
              alt={
                artworkAltText ||
                `artwork image for recording: ${artworkFile.name}`
              }
            />

            <br />

            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() =>
                handleImageChange({ target: { files: null } } as any)
              }
            >
              remove artwork image
            </button>
          </div>
        ) : (
          <div className="form-group mb-2">
            <label htmlFor="artworkFile">artwork image</label>
            <input
              id="artworkFile"
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
        )}

        <div className="form-group mb-2">
          <label htmlFor="artwork_alt_text">artwork alt text</label>
          <input
            id="artwork_alt_text"
            name="artwork_alt_text"
            className="form-control"
            type="text"
            value={artworkAltText}
            onChange={(e) => setArtworkAltText(e.target.value)}
          />
        </div>

        <button className="btn btn-primary" type="submit" disabled={loading}>
          {loading ? "loading..." : "create"}
        </button>

        <div>{error}</div>
      </form>

      <div className="mt-4">
        <Link to={routes.dashboard}>back</Link>
      </div>
    </main>
  );
};

export default CollectionsNewPage;
